
.consent-banner {
  width: 97%;
  max-width: 1200px;
  background-color: #06437c;
  color: #fff;
  border-bottom: none;
  padding: 20px 20px 5px 20px;
  border-radius: 5px 5px 0 0;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

.consent-text {
  text-align: left;
  display: inline-block;
  width: 75%;
}

  .consent-text a {
    text-decoration: underline;
  }

.consent-buttons {
  text-align: center;
  display: inline-block;
  width: 25%;
}

  .consent-banner h3 {
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 0;
  }

  .consent-banner p {
    margin: 10px 0;
    font-size: 0.9em;
  }

  .consent-banner p a {
    color: #fff;
  }

  .consent-reject {
    font-size: 0.9em;
    color: #ddd;
    text-decoration: underline;
    display: block;
    width: 100%;
    padding: 10px 0 5px 0;
    cursor: pointer;
  }

.cookie-diag a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .consent-banner {
    width: 100%;
    padding: 15px 10px 5px 10px;
  }

  .consent-banner h3 {
    font-size: 1em;
  }

  .consent-banner p {
    font-size: 0.8em;
    padding-right: 5px;
  }
  
  .consent-text {
    width: 100%
  }
  
  .consent-buttons {
    width: 100%
  }

  .consent-reject {
    width: auto;
    float: left;
  }

    .consent-buttons button {
      float: right;
      width: 150px;
    }

  .cookie-diag {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 400px) {
  .cookie-diag {
    font-size: 0.8em;
  }
}