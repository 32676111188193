
.software-logo {
  height: 50vh;
  width: auto;
  margin: 0 auto;
}

/* Problem section */

.software-problem-cont {
  line-height: 1.2em;
}

  .software-problem-cont p {
    max-width: 600px;
    margin: 10px auto;
  }

  .software-problem-cont ul {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding: 0;
  }

    .software-problem-cont ul li {
      display: block;
      margin: 10px;
      background-color: #06437c;
      padding: 15px 20px;
      min-width: 150px;
      text-align: left;
      border-radius: 5px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
    }

      .software-problem-cont ul li:first-of-type {
        margin-left: 0;
      }

      .software-problem-cont ul li:last-of-type {
        margin-right: 0;
      }

      .software-problem-cont ul li::before {
        content: '';
      }


.software-problem-img {
  width: 100%;
  margin: 20px 0 60px 0;
}

/* Explain Section */
.software-explain-section {
  padding-bottom: 150px;
  padding-top: 150px;
  position: relative;
}

.software-explain-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #000;
  opacity: 0.5;
}

.software-blips-cont {
  position: relative;
  margin-top: 20px;
  overflow: visible;
}

.software-blips-img {
  width: 100%;
}

.software-explain-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 0;
}

.software-explain-img {
  width: 25%;
}

.software-explain-connector {
  width: 5%;
  position: relative;
}

  .software-explain-connector .dot {
    width: 15px;
    height: 15px;
    background-color: #fc2619;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .software-explain-connector .line {
    height: 2px;
    width: 100%;
    background-color: #fc2619;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .software-explain-connector.alt .dot {
    left: auto;
    right: 0;
    transform: translate(50%, -50%);
  }

  .software-explain-text {
    text-align: left;
    border: 2px solid #fc2619;
    padding: 20px 10px;
    flex-grow: 1;
  }

    .software-explain-text p {
      margin: 0;
      font-size: 1.2em;
      line-height: 1.5em;
    }

.software-explain-connector.show-when-sm, .software-explain-text.show-when-sm {
  display: none;
}

/* Comparison table */
.software-compare-table {
  background-color: #06437c;
  border-collapse: collapse;
  margin-bottom: 100px;
}

.software-compare-head-img {
  display: block;
  width: 100%;
  margin: 20px auto;
  max-width: 400px;
}

  .software-compare-table th {
    padding: 20px 10px;
    font-size: 1.2em;
    text-align: center;
    width: 50%;
    vertical-align: top;
  }

    .software-compare-table th p {
      height: 40px;
      margin: 0;
    }

  .software-compare-table td {
    padding: 20px 10px 40px 10px;
    width: 50%;
    text-align: left;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
  }

    .software-compare-table td:first-of-type {
      text-align: right;
    }

  .software-compare-table td span {
    font-weight: 600;
    color: #5bb0ff;
  }

  .software-compare-table td:nth-of-type(2) span {
    color: #f84940;
  }

.comparison-bar {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 5px;
}

  .comparison-bar.good {
    background-color: #5bb0ff;
    right: 0;
    border-radius: 5px 0 0 5px;
  }

  .comparison-bar.bad {
    background-color: #fc2619;
    left: 0;
    border-radius: 0 5px 5px 0;
    border-left: 2px solid #fff;
  }

/* Benefits section */

.software-benefits-section {
  padding: 150px 0 300px 0;
  position: relative;
}

.software-benefits-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  opacity: 1;
  object-fit: cover;
}

.software-benefits-bg-color {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: rgba(0, 0, 0, 0.3);
}

.software-benefits-table {
  width: 100%;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border-collapse: collapse;
  font-size: 0.8em;
}

  .software-benefits-table th {
    padding: 5px;
    width: 40%;
  }

    .software-benefits-table th:nth-child(2) {
      width: 20%;
    }

  .software-benefits-table td {
    padding: 5px;
    width: 40%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

    .software-benefits-table td:nth-child(2) {
      width: 20%;
      padding: 5px 0;
    }

.software-applications-cont {
  margin-top: 100px;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .software-explain-section {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .software-benefits-section {
    padding: 100px 0 200px 0;
  }
}


@media screen and (max-width: 1000px) {
  .software-explain-cont {
    margin: 30px 0 50px 0;
  }

  .software-benefits-section {
    padding: 100px 0 150px 0;
  }

  .software-problem-cont ul {
    flex-wrap: wrap;
  }

  .software-problem-cont ul li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}


@media screen and (max-width: 800px) {
  .software-explain-cont {
    flex-direction: column;
  }
  
  .software-explain-img {
    width: 100%;
    max-width: 600px;
  }

  .software-explain-text {
    width: 100%;
    max-width: 600px;
  }

  .software-explain-connector.show-when-sm, .software-explain-text.show-when-sm {
    display: block;
  }

  .software-explain-connector.hide-when-sm, .software-explain-text.hide-when-sm {
    display: none;
  }

  .software-explain-connector {
    width: auto;
    height: 30px;
  }
  
    .software-explain-connector .dot {
      width: 12px;
      height: 12px;
      top: 0;
      left: 50%;
    }
  
    .software-explain-connector .line {
      height: 100%;
      width: 2px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  
    .software-explain-connector.alt .dot {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

  .software-compare-table {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  .software-explain-section {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .software-compare-table {
    font-size: 0.9em;
  }

  .software-benefits-section {
    padding: 80px 0 80px 0;
  }

  .software-problem-img {
    margin: 20px 0 40px 0;
  }

  .software-problem-cont ul li {
    padding: 10px;
  }
}