
.industries-cont {
  display: flex;
  flex-direction: row;
}

.industry-block {
  flex: 1;
  height: 800px;
  position: relative;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  overflow: hidden;
  margin: 0 5px;
}

  .industry-block.active {
    flex-grow: 3;
  }

  .industry-block:hover:not(.active) {
    flex-grow: 1.1;
  }

  .industry-block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.industry-head {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(0, 0) rotate(90deg);
  transform-origin: left top;
  color: white;
  font-size: 2em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 1;
  text-align: left;
  width: 300px;
  transition: transform 400ms ease-in-out;
}

  .industry-block.active .industry-head {
    transform: translate(-50%, 50px) rotate(0);
  }

.industry-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 100%);
  line-height: 1.5em;
  font-weight: 500;
  padding: 20px;
  color: white;
  text-align: justify;
  margin: 0;
  opacity: 0;
  transform: translateY(100%);
}

.industry-block.active .industry-description {
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms ease-in-out, opacity 400ms;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1400px) {
  .industry-block {
    height: 650px;
  }
}

@media screen and (max-width: 1200px) {
  .industry-head {
    position: absolute;
    top: 20px;
    left: 60%;
    transform: translate(0, 0) rotate(90deg);
    transform-origin: left top;
    color: white;
    font-size: 1.5em;
  }
}


@media screen and (max-width: 800px) {
  .industries-cont {
    flex-direction: column;
    height: 1000px;
  }
  
  .industry-block {
    width: 100%;
    height: auto;
    margin: 5px 0;
  }

  .industry-head {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translate(0, -100%);
    width: 200px;
  }
  
    .industry-block.active .industry-head {
      transform: translate(0, -180px) rotate(90deg);
    }
}

@media screen and (max-width: 600px) {
  .industries-cont {
    height: 700px;
  }

  .industry-head {
    width: 150px;
  }

  .industry-block.active .industry-head {
    transform: translate(-40px, -120px);
  }
}