
.standard-button {
  display: inline-block;
  padding: 15px 40px;
  background-color: #fc2619;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  transition: background-color 0.2s;
  position: relative;
  overflow: visible;
  cursor: pointer;
}

  .standard-button:hover {
    background-color: #a5241d;
  }

  .standard-button span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: Quantico;
    font-size: 1em;
  }

.corner-el-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-top: 3px solid #fc2619;
  border-right: 3px solid #fc2619;
  opacity: 0;
  transform: translate(20px, -20px);
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

  .standard-button:hover .corner-el-1 {
    transform: translate(8px, -8px);
    opacity: 1;
  }

.corner-el-2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-bottom: 3px solid #fc2619;
  border-left: 3px solid #fc2619;
  opacity: 0;
  transform: translate(-20px, 20px);
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

  .standard-button:hover .corner-el-2 {
    transform: translate(-8px, 8px);
    opacity: 1;
  }

.corner-el-3 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-bottom: 3px solid #fc2619;
  border-right: 3px solid #fc2619;
  opacity: 0;
  transform: translate(20px, 20px);
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

  .standard-button:hover .corner-el-3 {
    transform: translate(8px, 8px);
    opacity: 1;
  }

.corner-el-4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-top: 3px solid #fc2619;
  border-left: 3px solid #fc2619;
  opacity: 0;
  transform: translate(-20px, -20px);
  transition: transform 0.6s ease-in-out, opacity 0.6s;
}

  .standard-button:hover .corner-el-4 {
    transform: translate(-8px, -8px);
    opacity: 1;
  }