
.menu-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: transparent;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

  .menu-top.scrolled {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 2px 3px rgba(90, 90, 90, 0.1);
    height: 70px;
    backdrop-filter: blur(5px);
  }

.menu-top-inner {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.menu-top-logo {
  height: 60px;
  transition: height 0.5s ease-in-out;
}

  .menu-top-logo-img {
    height: 100%;
    width: auto;
  }

  .menu-top.scrolled .menu-top-logo {
    height: 55px;
  }

.menu-top-links {
  text-align: left;
}

.mobile-menu-toggle {
  display: none;
}

.drawer-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  padding: 50px 0 10px 0;
  background-color: #10284c;
  color: #fff;
  position: relative;
  height: 100%;
}

.drawer-menu-logo {
  width: 200px;
  margin: 10px auto 40px auto;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .menu-top-links {
    display: none;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .menu-top {
    height: 70px;
  }

    .menu-top.scrolled {
      height: 60px;
    }

  .menu-top-logo {
    height: 60px;
  }

    .menu-top.scrolled .menu-top-logo {
      height: 40px;
    }
}


@media screen and (max-width: 500px) {
  .menu-top-logo {
    height: 50px;
  }
}