
.page-transition {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scaleY(0);
  background-color: #333;
  z-index: 200;
}

.page-transition.open {
  animation: scaleUp 600ms forwards;
}

.page-transition.close {
  animation: scaleDown 600ms forwards;
}

@keyframes scaleUp {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}

@keyframes scaleDown {
  0% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}