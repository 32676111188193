
/* Header / Blips */
.drone-header {
  background-color: #002244;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 50px;
}

  .drone-header h1 {
    font-size: 5em;
    margin: 0;
  }
  
    .drone-header h1 span {
      color: #fc2619;
    }

.drone-blips-cont {
  position: relative;
  margin-top: 60px;
  overflow: visible;
}

.drone-blips-img {
  width: 100%;
}

/* Intro / gallery section */

.drone-intro-section {
  padding-top: 200px;
  padding-bottom: 200px;
}

.drone-intro-gal {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.drone-intro-gal-thumbs {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;
}

.drone-intro-gal-thumb-cont {
  width: 100%;
  position: relative;
  margin: 10px 0;
  padding-right: 10px;
}

.drone-intro-gal-thumb {
  width: 100%;
  height: 180px;
  object-fit: cover;
  position: relative;
  cursor: pointer;
}

  .drone-intro-gal-thumb-cont::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 180px;
    background-color: #fc2619;
    border-radius: 2px;
    z-index: 1;
    transform: scaleY(0);
    transition: transform 0.3s ease-in-out;
  }

  .drone-intro-gal-thumb-cont:not(.active):hover::after {
    transform: scaleY(0.1);
  }

  .drone-intro-gal-thumb-cont.active::after {
    transform: scaleY(1);
  }

  .drone-intro-gal-thumbs h3 {
    margin: 5px 0 20px 0;
  }

.drone-intro-gal-main-cont {
  width: calc(100% - 300px);
  height: 700px;
  position: relative;
}

.drone-intro-gal-main {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

  .drone-intro-gal-main.active {
    opacity: 1;
  }

/* Features section */

.drone-features-section {
  padding: 150px 0 300px 0;
  position: relative;
}

.drone-features-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  opacity: 1;
  object-fit: cover;
}

.drone-features-bg-color {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: rgba(0, 0, 0, 0.3);
}

.drone-features-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 50px;
}

.drone-features-block {
  width: calc(33.3333% - 20px);
  padding: 30px 20px;
  background-color: #06437c;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

  .drone-features-block h3 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .drone-features-block p {
    margin: 0;
    line-height: 1.5em;
  }

/* Payloads section */

.drone-payloads-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

/* Specs section */
.drone-specs-section {
  padding-bottom: 150px;
  padding-top: 150px;
  position: relative;
}

.drone-specs-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #000;
  opacity: 0.5;
}

.drone-specs-tables-cont {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.drone-specs-table {
  width: calc(50% - 10px);
  background-color: #06437c;
  border-collapse: collapse;
}

  .drone-specs-table td {
    padding: 30px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .drone-specs-table td:nth-of-type(odd) {
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.08em;
    width: 70%;
  }

  .drone-specs-table td:nth-of-type(even) {
    text-align: left;
    width: 30%;
    min-width: 65px;
  }

  .drone-specs-table td ul {
    margin: 0;
    padding: 0 0 0 20px;
  }

  .drone-specs-table tr:last-of-type td {
    border-bottom: none;
  }

/* Industries section */

.drone-industries {
  padding-top: 150px;
  padding-bottom: 150px;
}

/* Box section */

.drone-box-section {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.drone-box-bg-color {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: rgba(0, 0, 0, 0.3);
}

.drone-box-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  justify-content: space-between;
  align-items: stretch;
}

.drone-box-view {
  display: inline-block;
  width: 600px;
  margin: 0 20px;
  position: relative;
}

  .drone-box-view img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    opacity: 0;
    transform: translateY(-50%) scale(0.9);
    transition: opacity 0.3s, transform 0.3s ease-in-out;
  }

    .drone-box-view img.active {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }

.drone-box-items {
  min-width: 300px;
}

.db-item-cont {
  margin: 50px 0;
  height: 130px;
  cursor: pointer;
  transition: transform 0.2s;
}

  .db-item-cont:hover {
    transform: scale(1.1);
  }

  .db-item-cont:hover .db-item-img {
    background-color: #bb1f17;
  }

  .db-item-cont.active .db-item-img {
    background-color: #fc2619;
  }

.db-item-img {
  display: inline-block;
  width: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  vertical-align: top;
}

  .db-item-img img {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

.db-item-text {
  display: inline-block;
  width: 200px;
  vertical-align: top;
  padding: 0 10px;
  text-align: left;
}

  .db-item-text h3 {
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
  }

  .db-item-text p {
    margin: 0;
    font-size: 0.9em;
  }

  .db-item-text.right {
    text-align: right;
  }

/* CTA section */

.drone-cta-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.drone-cta-text {
  max-width: 600px;
  margin: 30px auto;
}

.drone-cta-button {
  margin: 0 10px 40px 10px;
  width: 200px;
  height: 60px;
  padding: 5px;
  overflow: hidden;
}

.drone-cta-img {
  width: 100%;
}

/* Image Header */
.drone-image-header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 50px;
  position: relative;
}

  .drone-image-header h1 {
    font-size: 5em;
    margin: 0;
  }

  .drone-image-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

/* Calisto */

.calisto-explain-section {
  padding-bottom: 150px;
  padding-top: 150px;
  position: relative;
}

.calisto-explain-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #000;
  opacity: 0.5;
}

.calisto-explain-cont {
  text-align: left;
  line-height: 1.2em;
}

.callisto-explain-points {
  display: flex;
  flex-direction: column;
  padding: 0;
}

  .callisto-explain-points li {
    display: block;
    margin-bottom: 10px;
    background-color: #06437c;
    padding: 15px 20px;
    min-width: 150px;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
  }

.calisto-explain-img {
  width: 100%;
  margin: 20px 0 60px 0;
}

.callisto-table {
  margin-top: 40px;
}

.drone-specs-table.callisto-table td:nth-of-type(odd) {
  width: 40%;
}

.drone-specs-table.callisto-table td:nth-of-type(even) {
  width: 60%;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .drone-intro-section {
    padding-top: 100px;
    padding-bottom: 150px;
  }

  .drone-intro-gal-thumbs {
    width: 250px;
  }

  .drone-intro-gal-thumb-cont {
    margin: 5px 0;
  }

  .drone-intro-gal-thumb, .drone-intro-gal-thumb-cont::after {
    height: 150px;
  }
  
  .drone-intro-gal-main-cont {
    width: calc(100% - 250px);
    height: 560px;
  }

  .drone-features-section {
    padding: 120px 0 200px 0;
  }

  .drone-payloads-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .drone-specs-section {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .drone-industries {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .drone-box-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .drone-box-items {
    width: 200px;
    min-width: 200px;
  }

  .drone-box-view {
    flex-grow: 1;
  }

  .db-item-cont {
    margin: 30px 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .db-item-img {
    width: 80px;
  }
  
    .db-item-img img {
      padding: 12px;
    }
  
  .db-item-text {
    width: 100%;
    padding: 10px 0 0 0;
    text-align: center;
  }

  .drone-cta-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .calisto-explain-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .drone-intro-section {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .drone-header h1 {
    font-size: 3em;
  }

  .drone-intro-gal-thumbs {
    width: 200px;
  }

  .drone-intro-gal-thumb, .drone-intro-gal-thumb-cont::after {
    height: 120px;
  }
  
  .drone-intro-gal-main-cont {
    width: calc(100% - 200px);
    height: 470px;
  }

  .drone-features-section {
    padding: 100px 0 150px 0;
  }

  .drone-features-block {
    width: calc(33.3333% - 10px);
    padding: 10px;
    background-color: #06437c;
  }

  .drone-specs-highlights {
    margin: 50px 0 30px 0;
  }

  .drone-specs-highlight h3 {
    padding-bottom: 15px;
  }

  .drone-specs-highlight p {
    margin: 15px 0 0 0;
  }

  .drone-image-header h1 {
    font-size: 3em;
  }
}

@media screen and (max-width: 800px) {
  .drone-intro-gal {
    flex-direction: column;
  }

  .drone-intro-gal-thumbs {
    width: 100%;
    flex-direction: row;
    position: relative;
    padding: 30px 0 0 0;
  }

    .drone-intro-gal-thumbs h3 {
      position: absolute;
      top: 2px;
      left: 0;
      width: 100%;
      text-align: center;
    }

  .drone-intro-gal-main-cont {
    width: 100%;
    height: 400px;
  }

  .drone-intro-gal-thumb-cont {
    margin: 0 5px;
    padding-right: 0;
    padding-bottom: 5px;
  }

    .drone-intro-gal-thumb-cont:first-of-type {
      margin-left: 0;
    }

    .drone-intro-gal-thumb-cont:last-of-type {
      margin-right: 0;
    }

  .drone-intro-gal-thumb-cont::after {
    top: auto;
    bottom: 0px;
    height: 5px;
    width: 100%;
    transform: scaleX(0);
  }

    .drone-intro-gal-thumb-cont.active::after {
      transform: scaleX(1);
    }

    .drone-intro-gal-thumb-cont:not(.active):hover::after {
      transform: scaleX(0.1);
    }

  .drone-features-blocks {
    flex-direction: column;
    margin-top: 30px;
  }

  .drone-features-block {
    width: 100%;
    margin-bottom: 20px;
    min-height: auto;
    padding: 20px 10px;
  }

  .drone-features-section {
    padding: 80px 0 100px 0;
  }

  .drone-specs-head {
    padding: 10px 70px 10px 20px;
  }

  .drone-box-cont {
    margin-top: 20px;
    flex-direction: column;
  }

  .drone-box-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .db-item-cont {
    margin: 0;
    width: calc(33.333% - 5px);
    font-size: 0.9em;
  }

  .drone-box-view {
    width: 100%;
    margin: 10px 0;
    height: 300px;
  }
  
    .drone-box-view img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  .drone-cta-button {
    margin: 0 5px 30px 5px;
    width: 120px;
    padding: 15px 0;
  }
}

@media screen and (max-width: 600px) {
  .drone-intro-gal-main-cont {
    height: 300px;
  }

  .drone-intro-gal-thumb {
    height: 100px;
  }

  .drone-payloads-section {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .drone-specs-highlights {
    margin: 30px 0 20px 0;
  }

  .drone-specs-highlight {
    width: calc(33.3333% - 15px);
    font-size: 0.9em;
  }

  .drone-specs-section {
    padding-top: 80px;
  }

  .drone-industries {
    padding-top: 80px;
  }

  .drone-cta-section {
    padding-top: 80px;
  }

  .db-item-cont:hover {
    transform: scale(1);
  }

  .drone-specs-table td {
    padding: 15px 10px;
    font-size: 0.9em;
  }

  .calisto-explain-section {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .calisto-explain-img {
    margin: 20px 0 40px 0;
  }

  .drone-specs-tables-cont.callisto-table-cont {
    flex-direction: column;
  }

  .drone-specs-table.callisto-table {
    width: 100%;
    margin-bottom: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 450px) {
  .db-item-cont {
    font-size: 0.8em;
  }

  .db-item-img {
    width: 60px;
  }

  .drone-cta-button {
    margin: 0 0 10px 0;
    width: 100%;
    height: 45px;
  }

  .drone-cta-img {
    margin-top: 30px;
  }
}