
/* SPECS */
.home-spec {
  padding-left: 50%;
  padding-top: 50px;
  position: relative;
  margin-top: 50px;
}

  .home-spec.alt {
    padding-left: 0;
    padding-right: 50%;
  }

.home-spec-content {
  padding: 50px 80px;
  min-height: 500px;
  border-radius: 10px;
  position: relative;
}

  .home-spec-content-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, #064886, #001121);
    border-radius: 10px;
    z-index: -1;
  }

    .home-spec.alt .home-spec-content-bg {
      background: linear-gradient(to right bottom, #064886, #001121);
    }

  .home-spec-content-bg-border {
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-image: linear-gradient(156deg, rgba(255, 255, 255, .3), rgba(153, 153, 153, 0) 40%, rgba(235, 235, 235, 0) 100%, rgba(255, 255, 255, .3));
    border-radius: 10px;
    z-index: -2;
  }

    .home-spec.alt .home-spec-content-bg-border {
      background-image: linear-gradient(156deg, rgba(255, 255, 255, .3), rgba(153, 153, 153, 0) 0%, rgba(235, 235, 235, 0) 65%, rgba(255, 255, 255, .3));
    }

.home-spec-img-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 90%;
  object-fit: cover;
  z-index: -1;
}

  .home-spec.alt .home-spec-img-cont {
    left: auto;
    right: 0;
  }

.home-spec-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-spec-head-icon {
  width: 80px;
  height: 80px;
}

.home-spec-content h3 {
  font-size: 2em;
  margin: 15px 0;
}

.home-spec-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

  .home-spec-item-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .home-spec-item p {
    font-style: italic;
    line-height: 1.5em;
    text-align: left;
  }

.home-spec-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

  .home-spec-nav p {
    margin: 0 20px;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .home-spec-content {
    padding: 40px;
    min-height: 250px;
  }

  .home-spec-head-icon {
    width: 60px;
    height: 60px;
  }

  .home-spec-item-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
}


@media screen and (max-width: 700px) {
  .home-spec-img-cont {
    position: static;
    width: 100%;
    height: 300px;
  }

  .home-spec-head-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .home-spec {
    padding-left: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .home-spec.alt {
    padding-left: 0;
    padding-right: 0;
  }

  .home-spec-content {
    padding: 10px 20px;
    min-height: auto;
    margin-top: 10px;
  }

  .home-spec-content h3 {
    margin-top: 0;
  }

  .home-spec-item {
    margin-bottom: 10px;
  }
}


@media screen and (max-width: 500px) {
  .home-spec-img-cont {
    height: 250px;
  }
}