
.background-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -4;
}

.background-effect-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

  .background-effect-line {
    width: 1px;
    height: 100%;
    background-color: #fff;
    opacity: 0.02;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 800px) {
  .background-effect {
    display: none;
  }
}