
/* HEADER / HERO */

.home-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

.home-header-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  object-fit: cover;
}

.home-header-bg-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 17, 33, 0.2);
}

.home-header-content {
  text-align: left;
}

  .home-header-content h1 {
    font-size: 4em;
    margin: 0;
  }

  .home-header-divider {
    width: 200px;
    height: 2px;
    background-color: #fff;
    margin: 30px 0;
  }

  .home-header-content p {
    font-size: 1.4em;
    margin: 0;
  }

.home-header-button {
  margin-top: 20px;
}

/* Intro section */

.home-intro-1 {
  padding-top: 250px;
  padding-bottom: 250px;
}

.home-intro-1 h2 {
  max-width: 450px;
  margin: 30px auto;
}

.home-intro-1-text {
  max-width: 600px;
  margin: 30px auto;
}

  .home-intro-1-text a {
    color: #fff;
  }


/* Drones Intro section */

.home-drones-intro {
  padding: 250px 0 200px 0;
  position: relative;
}

.home-drones-intro-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;
  object-fit: cover;
}

.home-drones-intro-bg-color {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: rgba(0, 0, 0, 0.5);
}

.home-drones-intro-blocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.home-drones-intro-block {
  margin: 0 50px;
  background-color: transparent;
  padding: 20px;
}

  .home-drones-intro-block:first-child {
    margin-left: 0;
  }

  .home-drones-intro-block:last-child {
    margin-right: 0;
  }

  .home-drones-intro-block h3 {
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 0.07em;
  }

  .home-drones-intro-block p {
    margin: 0 auto 30px auto;
    max-width: 350px;
  }

/* Drone section */

.home-drone {
  padding: 100px 30px;
  position: relative;
  min-height: 800px;
  margin-bottom: 40px;
}

.home-drone-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  object-fit: cover;
}

.home-drone-head h2 {
  font-size: 5em;
  margin: 0;
  font-weight: 600;
}

.home-drone-head h2 span {
  color: #fc2619;
}

.home-drone-head p {
  margin: 0 0 10px 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2em;
}

.home-drone-content {
  margin-bottom: 200px;
  position: relative;
}

.info-text-block {
  margin: 20px auto;
  text-align: justify;
  line-height: 1.8em;
  margin: 0 0 80px 0;
}

.home-drone-industries {
  margin-top: 100px;
}

.home-drone-button {
  margin-top: 50px;
  min-width: 50%;
  height: 80px;
}

/* Video section */

.home-videos-section {
  padding-bottom: 150px;
  padding-top: 150px;
  position: relative;
}

.home-videos-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #000;
  opacity: 0.5;
}

.home-video {
  width: 100%;
  height: auto;
  background-color: transparent;
  margin-bottom: 30px;
}

/* CTA section */

.home-cta-section {
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.home-cta-text {
  max-width: 600px;
  margin: 30px auto;
}

.home-cta-button {
  margin-bottom: 40px;
}

.home-cta-img {
  width: 100%;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .home-intro-1 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .home-drones-intro {
    padding: 200px 0 150px 0;
  }

  .home-drone {
    min-height: 600px;
  }

  .home-videos-section {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .home-cta-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .home-header-content h1 {
    font-size: 3em;
  }

  .home-header-divider {
    width: 150px;
    margin: 25px 0;
  }

  .home-header-content p {
    font-size: 1.2em;
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .home-intro-1 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .home-drones-intro-block {
    margin: 0 20px;
    padding: 10px;
  }
  
    .home-drones-intro-block h3 {
      font-size: 1.5em;
    }
  
  .home-drone {
    min-height: 400px;
    padding: 50px 20px;
  }

  .home-drone-head h2 {
    font-size: 3em;
  }
  
  .home-drone-head p {
    font-size: 1.5em;
  }

  .home-drone-content {
    margin-bottom: 150px;
  }
}


@media screen and (max-width: 600px) {
  .home-drones-intro {
    padding: 100px 0 80px 0;
  }

  .home-drones-intro-blocks {
    flex-direction: column;
  }

  .home-drones-intro-block {
    margin: 0 0 30px 0;
    padding: 0;
  }

  .info-text-block {
    margin: 0 0 50px 0;
  }

  .home-drone-button {
    margin-top: 30px;
    min-width: 40%;
    height: 60px;
  }

  .home-drone-content {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .home-intro-1 h2 {
    font-size: 2em;
  }

  .home-drone {
    min-height: 350px;
  }
}