
footer {
  background-color: #001122;
  padding-top: 50px;
}

.footer-top {
  display: flex;
  flex-direction: row;
}

.footer-left {
  width: 50%;
  text-align: left;
  padding-right: 20px;
}

.footer-logo-cont {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}

.footer-logo {
  width: 100%;
}

.footer-head {
  margin: 0 0 20px 0;
}

.footer-socials {
  display: flex;
}

.footer-about {
  max-width: 370px;
  line-height: 1.5em;
}

.footer-right {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.footer-right-nav {
  width: 50%;
  text-align: left;
  padding-right: 20px;
}

.footer-right-contact {
  width: 50%;
  text-align: left;
}

  .footer-right-contact p a {
    color: #fff;
  }

.footer-divider {
  width: 100%;
  height: 1px;
  background-color: #fc2619;
  margin: 30px 0 20px 0;
}

.footer-legal {
  color: #9e9e9e;
  margin: 0;
  padding: 10px 0;
  font-size: 0.8em;
  padding-bottom: 50px;
}

  .footer-legal a {
    color: #9e9e9e;
    text-decoration: underline;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 800px) {
  .footer-logo-cont {
    max-width: 200px;
  }
}


@media screen and (max-width: 600px) {
  .footer-top {
    display: flex;
    flex-direction: column;
  }

  .footer-left {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding-right: 0;
  }

  .footer-about {
    max-width: 100%;
    text-align: left;
  }

  .footer-right {
    width: 100%;
  }
}