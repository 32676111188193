
.component-block {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 0 50px 0;
  border-radius: 10px;
  background-color: transparent;
  overflow: visible;
}

  .component-block-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left top, #064886, #001121);
    border-radius: 10px;
    z-index: -1;
  }

    .component-block.alt .component-block-bg {
      background: linear-gradient(to right bottom, #064886, #001121);
    }

  .component-block-bg-border {
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-image: linear-gradient(156deg, rgba(255, 255, 255, .3), rgba(153, 153, 153, 0) 40%, rgba(235, 235, 235, 0) 100%, rgba(255, 255, 255, .3));
    border-radius: 10px;
    z-index: -2;
  }

    .component-block.alt .component-block-bg-border {
      background-image: linear-gradient(156deg, rgba(255, 255, 255, .3), rgba(153, 153, 153, 0) 0%, rgba(235, 235, 235, 0) 65%, rgba(255, 255, 255, .3));
    }

.component-block-img {
  width: 40%;
  height: 300px;
  object-fit: contain;
  margin: 0 0 0 5%;
  transition: transform 0.5s ease-in-out;
}

  .component-block.alt .component-block-img {
    margin: 0 5% 0 0;
  }

  .component-block:hover .component-block-img {
    transform: scale(1.05);
  }

.component-block-content {
  width: 55%;
  padding: 20px;
  text-align: left;
}

  .component-block-content h3 {
    margin: 30px 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .component-block-content p {
    margin: 0;
    line-height: 1.5em;
  }

  .component-block-content a {
    display: block;
    color: #fff;
    text-decoration: underline;
    margin: 10px 0;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1000px) {
  .component-block-img {
    width: 30%;
    height: 250px;
    margin: 0 0 0 3%;
    object-fit: contain;
  }

    .component-block.alt .component-block-img {
      margin: 0 3% 0 0;
    }

  .component-block-content {
    width: 68%;
  }
}

@media screen and (max-width: 800px) {
  .component-block {
    align-items: center;
    margin: 0 0 20px 0;
  }

  .component-block-img {
    width: 40%;
    height: 200px;
    margin: 0 0 0 2%;
  }

  .component-block-content {
    width: 58%;
  }

  .component-block-content h3 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .component-block-content p {
    line-height: 1.3em;
  }
}

@media screen and (max-width: 600px) {
  .component-block-content {
    font-size: 0.9em;
    padding: 20px 10px;
    opacity: 0.8;
  }
}