
/* ======= CONTACT ======= */
.contact-form-section {
  padding-top: 120px;
}

  .contact-form-section h2 {
    font-size: 1.5em;
    margin: 0 0 30px 0;
  }

.contact-form {
  background-color: #034079;
  padding: 20px;
}

/* ======= DETAILS ======= */
.contact-details-section {
  padding-top: 100px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.map {
  width: 50%;
  min-height: 300px;
  border: none;
}

.contact-details {
  width: 50%;
  padding-right: 20px;
  text-align: left;
}

  .contact-details h2 {
    font-size: 1.5em;
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: uppercase;
  }

  .contact-details table {
    width: 100%;
  }

  .contact-details td {
    padding: 5px 0;
  }

  .contact-details td b {
    display: inline-block;
    padding-top: 20px;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .contact-form-section {
    padding-top: 100px;
  }

  .contact-details-section {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .contact-form-section h2 {
    margin: 0 0 20px 0;
  }
}

@media screen and (max-width: 600px) {
  .contact-form-section {
    padding-top: 80px;
  }

  .contact-form-section h2 {
    text-align: left;
  }

  .contact-details-section {
    padding-top: 60px;
    padding-bottom: 80px;
    flex-direction: column;
  }
  
  .map {
    width: 100%;
    height: 300px;
  }
  
  .contact-details {
    width: 100%;
    padding: 0 0 20px 0;
  }
}


@media screen and (max-width: 700px) {
  .map {
    height: 250px;
  }
}