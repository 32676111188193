/* Header */
.about-header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 50px;
  position: relative;
}

  .about-header h1 {
    font-size: 5em;
    margin: 0;
  }

  .about-header-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

/* History */

.about-history-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.about-intro-text {
  max-width: 600px;
  text-align: justify;
  line-height: 1.5em;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  margin: 60px auto 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 5px;
}

/* Container around content */
.timeline .container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-bottom: 50px;
}

  .timeline .container.left {
    padding-left: 0;
  }

  .timeline .container.right {
    padding-right: 0;
  }

/* The circles on the timeline */
.timeline .container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid #001122;
  top: 20px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.timeline .left {
  left: 0;
}

/* Place the container to the right */
.timeline .right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline .left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #034079;
}

/* Add arrows to the right container (pointing left) */
.timeline .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #034079 transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline .right::after {
  left: -12px;
}

/* The actual content */
.timeline .content {
  padding: 20px 10px;
  background-color: #034079;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.24), 0px 2px 1px -1px rgba(0, 0, 0, 0.22);
}

.timeline .content h3 {
  font-size: 1.5em;
}

.timeline .content img {
  width: auto;
  max-height: 90%;
  object-fit: contain;
  margin-top: 10px;
  position: absolute;
  top: 0;
  left: calc(100% + 70px);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

  .timeline .right .content img {
    left: auto;
    right: calc(100% + 70px);
  }

/* Meet the team */
.about-team-header {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 800px;
}

.about-team-header-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  object-position: 50% 20%;
}

.about-team-section {
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: rgba(0, 0, 0, 0.3);
}

.team-cont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.team-member {
  width: 30%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

  .team-member img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

.team-member-text {
  text-align: left;
  border-left: 1px solid #fc2619;
  padding-left: 10px;
  margin-top: 20px;
  flex-grow: 1;
}

  .team-member-text h3 {
    font-size: 1.5em;
    margin: 0;
    font-weight: 600;
  }

  .team-member-text p {
    margin: 5px 0 0 0;
    font-size: 0.9em;
    /* text-transform: uppercase; */
  }

/* News / Blog */
.news-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.news-item-featured {
  padding-left: 50%;
  padding-top: 50px;
  position: relative;
  margin-top: 50px;
}

.news-item-featured-content {
  background-color: #034079;
  padding: 20px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.news-item-featured img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 90%;
  object-fit: cover;
  z-index: -1;
}

.news-type {
  padding-left: 20px;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 600;
}

  .news-type::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #fc2619;
    top: 0;
    left: 0;
    border-radius: 2px;
  }

.news-head {
  font-size: 2em;
  margin: 15px 0;
}

.news-body {
  line-height: 1.5em;
  margin: 0;
}

.news-date {
  margin-top: auto;
  padding-top: 20px;
  margin-bottom: 0;
  text-align: right;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
}

.news-link {
  width: 200px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 0.9em;
  background-color: #fff;
  color: #034079;
}

  .news-link:hover {
    background-color: #d7ecff;
  }

.news-link.fixed {
  display: inline-block;
  position: absolute;
  bottom: 12px;
  left: 10px;
  background-color: transparent;
  padding: 10px;
  color: #fff;
  width: auto;
  text-decoration: underline;
}

.news-cont {
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-item {
  width: 30%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

  .news-item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

.news-item-content {
  text-align: left;
  padding: 40px 20px 20px 20px;
  background-color: #034079;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.news-item-content .news-head {
  font-size: 1.5em;
}

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1400px) {
  .about-team-header {
    min-height: 700px;
  }
}

@media screen and (max-width: 1200px) {
  .about-history-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .about-team-header {
    min-height: 600px;
  }

  .about-team-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .news-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .timeline .container {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1000px) {
  .about-header {
    padding-top: 120px;
  }

  .about-header h1 {
    font-size: 3em;
  }

  .timeline .content {
    padding: 15px 10px;
  }
  
  .timeline .content h3 {
    font-size: 1.2em;
    margin: 10px 0;
  }

  .about-team-header {
    min-height: 450px;
  }

  .team-member {
    width: calc(33.3333% - 15px);
    margin-bottom: 40px;
  }
  
    .team-member img {
      height: 300px;
    }

  
  .news-item-featured-content {
    min-height: 350px;
  }

  .news-cont {
    padding-top: 60px;
  }

  .news-item {
    width: calc(33.3333% - 15px);
    margin-bottom: 30px;
  }

  .news-item img {
    height: 220px;
  }

  .news-item-content {
    padding: 30px 15px 15px 15px;
  }

  .news-item-content .news-head {
    font-size: 1.5em;
  }

  .news-link.fixed {
    bottom: 5px;
    left: 5px;
  }
}

@media screen and (max-width: 800px) {
  .team-member img {
    height: 250px;
  }

  .news-item-featured img {
    display: block;
    position: static;
    width: 100%;
    height: 300px;
    border-radius: 5px 5px 0 0;
  }

  .news-item-featured {
    padding-left: 0;
    padding-top: 0;
    margin-top: 30px;
  }

  .news-item-featured-content {
    min-height: auto;
    border-radius: 0 0 5px 5px;
  }

  .news-item {
    width: calc(50% - 15px);
  }

  .news-item-content {
    padding: 20px 10px 10px 10px;
  }

  .timeline .container {
    margin-bottom: 20px;
  }

  .news-link.fixed {
    padding: 5px;
  }
}

@media screen and (max-width: 600px) {
  .about-history-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .timeline::after {
    left: 30px;
  }

  .timeline .container.left, .timeline .container.right {
    width: 100%;
    padding-left: 60px;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .timeline .container::before {
    left: 50px;
    border-width: 10px 10px 10px 0;
    border-color: transparent #034079 transparent transparent;
  }

  .timeline .left::after, .timeline .right::after {
    left: 18px;
  }

  .timeline .right {
    left: 0%;
    right: auto;
  }

  .timeline .content img {
    position: static;
    width: 95%;
    height: 100px;
    background-color: transparent;
  }
  
    .timeline .right .content img {
      left: auto;
      right: calc(100% + 70px);
    }

  .about-team-header {
    min-height: 300px;
  }

  .about-team-section {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .team-member {
    width: calc(50% - 10px);
    margin-bottom: 30px;
    font-size: 0.9em;
  }

  .news-item-featured img {
    height: 250px;
  }

  .news-item-featured {
    font-size: 0.9em;
  }

  .news-section {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .news-head {
    font-size: 1.5em;
  }

  .news-item {
    width: 100%;
    font-size: 0.9em;
  }

  .news-cont {
    padding-top: 30px;
  }
}