
.blip-cont {
  position: absolute;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.blip {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fc2619;
  transform: translate(-50%, -50%);
  animation: grow 2s infinite ease-in-out;
}

  .blip-cont.alt-color .blip {
    background-color: #5bb0ff;
  }

.blip-outer {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #fc2619;
  transform: translate(-50%, -50%);
  animation: grow 2s infinite;
}

.blip-cont.alt-color .blip-outer {
    border-color: #5bb0ff;
  }

/* Grow and shrink animation */
@keyframes grow {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.blip-number {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 2em;
  font-weight: 800;
  color: #fff;
  margin: 0;
}

.blip-line-vertical {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 60px;
  background-color: #fc2619;
  pointer-events: none;
  transition: transform 0.3s ease-out 0.3s; /* transition out */
}

  .blip-cont.alt-color .blip-line-vertical {
    background-color: #5bb0ff;
  }

  .blip-cont:hover .blip-line-vertical {
    transition: transform 0.3s ease-in; /* transition in */
  }

  .blip-line-vertical.top {
    transform: translate(-50%, -100%) scaleY(0);
    transform-origin: center bottom;
  }
    
    .blip-cont:hover .blip-line-vertical.top {
      transform: translate(-50%, -100%) scaleY(1);
    }

  .blip-line-vertical.bottom {
    transform: translate(-50%, 0) scaleY(0);
    transform-origin: center top;
  }

    .blip-cont:hover .blip-line-vertical.bottom {
      transform: translate(-50%, 0) scaleY(1);
    }

.blip-line-horizontal {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 2px;
  background-color: #fc2619;
  pointer-events: none;
  transition: transform 0.3s ease-in; /* transition out */
}

  .blip-cont.alt-color .blip-line-horizontal {
    background-color: #5bb0ff;
  }

  .blip-cont:hover .blip-line-horizontal {
    transition: transform 0.3s ease-out 0.3s; /* transition in */
  }

  .blip-line-horizontal.left.bottom {
    transform: translate(-100%, 60px) scaleX(0);
    transform-origin: center right;
  }

    .blip-cont:hover .blip-line-horizontal.left.bottom {
      transform: translate(-100%, 60px) scaleX(1);
    }

  .blip-line-horizontal.left.top {
    transform: translate(-100%, -60px) scaleX(0);
    transform-origin: center right;
  }

    .blip-cont:hover .blip-line-horizontal.left.top {
      transform: translate(-100%, -60px) scaleX(1);
    }

  .blip-line-horizontal.right.bottom {
    transform: translate(0, 60px) scaleX(0);
    transform-origin: center left;
  }

    .blip-cont:hover .blip-line-horizontal.right.bottom {
      transform: translate(0, 60px) scaleX(1);
    }

  .blip-line-horizontal.right.top {
    transform: translate(0, -60px) scaleX(0);
    transform-origin: center left;
  }

    .blip-cont:hover .blip-line-horizontal.right.top {
      transform: translate(0, -60px) scaleX(1);
    }

.blip-text {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  background-color: rgba(30, 30, 30, 0.8);
  border: 2px solid #fc2619;
  transition: opacity 0.5s;
  width: 300px;
  padding: 10px;
  text-align: left;
  transform: translate(0, -50%);
  z-index: 5;
}

  .blip-cont.alt-color .blip-text {
    border-color: #5bb0ff;
  }

  .blip-cont:hover .blip-text {
    transition: opacity 0.5s 0.4s;
    opacity: 1;
  }

  .blip-text.top.right {
    top: -35px; /* (60px - 50px/2) */
    left: 85px; /* 50px + (60px - 50px/2) */
  }

  .blip-text.top.left {
    top: -35px;
    right: 85px;
  }

  .blip-text.bottom.right {
    top: 85px;
    left: 85px;
  }

  .blip-text.bottom.left {
    top: 85px;
    right: 85px;
  }

  .blip-text h3 {
    margin: 0 0 15px 0;
    font-weight: 600;
    font-size: 1em;
  }

  .blip-text p {
    margin: 0;
    font-size: 0.9em;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 800px) {
  .blip-line-vertical {
    transition: transform 0.3s ease-out; /* remove delay */
  }

  .blip-line-horizontal {
    display: none;
  }

  .blip-text {
    width: 200px;
    transform: translate(0, 0);
  }

  .blip-cont:hover .blip-text {
    transition: opacity 0.5s 0.2s;
  }

  .blip-text.top.right {
    bottom: 85px;
    top: auto;
    left: -10px;
  }

  .blip-text.top.left {
    bottom: 85px;
    top: auto;
    right: -10px;
  }

  .blip-text.bottom.right {
    top: 85px;
    left: -10px;
  }

  .blip-text.bottom.left {
    top: 85px;
    right: -10px;
  }
}

@media screen and (max-width: 600px) {
  .blip {
    width: 15px;
    height: 15px;
  }
  
  .blip-outer {
    width: 20px;
    height: 20px;
  }

  .blip-number {
    left: 45px;
    font-size: 1.5em;
  }
}