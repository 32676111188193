
.resources-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.resources-unlock {
  margin-top: 10px;
  width: 300px;
}

.resources-cont {
  padding: 30px 0;
}

.resources-head {
  margin: 0 0 20px 0;
  font-size: 1.5em;
}

  .resources-head.top-space {
    padding-top: 20px;
  }

.resource-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #034079;
  padding: 10px;
  margin-bottom: 20px;
}

  .resource-item img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  .resource-item h4 {
    margin-right: auto;
    text-align: left;
  }

  .resource-download {
    margin-left: 20px;
  }

/* ------- RESPONSIVENESS -------- */

@media screen and (max-width: 1200px) {
  .resources-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}


@media screen and (max-width: 600px) {
  .resource-item img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .resource-download {
    padding: 15px 30px;
    margin-left: 15px;
  }

  .resources-cont {
    padding: 10px 0;
  }
}